
	import PageBase from '@/Page/Base.vue';
	import { Component } from 'vue-property-decorator';
	import { mounted } from '@/Utility/Decorators';
	import { sleep } from '@/Utility/Helpers';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project BunnyDragon
	 */
	@Component
	export default class PageNotFound extends PageBase {
		/**
		 * @type number
		 */
		protected timeout: number = 1000 * 2;

		/**
		 * @return Promise<void>
		 */
		@mounted
		protected async redirectHome(): Promise<void> {
			await sleep(this.timeout);
			this.$router.push({ name: 'PageHome' });
		}
	}
